.wrapper {
	position: fixed;
	top: 0;
	left: 0;

	height: 100vh;
	width: 100%;

	background: #2179ffff;

	z-index: 999;

	display: flex;

	justify-content: center;
	align-items: center;
}

.loadAnim {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.loadAnim div {
	position: absolute;
	border: 4px solid #fff;
	opacity: 1;
	border-radius: 50%;
	animation: loadAnim 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loadAnim div:nth-child(2) {
	animation-delay: -0.5s;
}
@keyframes loadAnim {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}
